<template>
  <div class="projects-info-list item-list item-report-summary discount-info-table">
    <div
      class="summary-toolbar"
      v-if="title"
    >
      <h2>{{title}}</h2>
    </div>

    <table v-if="packageInfo">
      <thead>
        <tr>
          <th>Name</th>
          <th>Resource Key</th>
          <th>Key</th>
          <th>Price</th>
          <th>E.B Price</th>
          <th>Index</th>
          <th>Type</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="info in packageInfo"
          :key="info.key"
        >
          <td>
            {{info.name}}
          </td>
          <td>
            {{info.resource_key}}
          </td>
          <td>
            {{info.key}}
          </td>
          <td>
            <span>
              ${{info.price.unit_amount.usd ? info.price.unit_amount.usd : '0' }}</span>
             | <span>€{{info.price.unit_amount.eur ? info.price.unit_amount.eur : '0' }}</span> |
              <span>₹{{info.price.unit_amount.inr ? info.price.unit_amount.inr : '0'}}
            </span>
          </td>
          <td>
            {{info.show_early_bird_pricing ? '✅' : 'No'}}
          </td>
           <td>
            {{info.index >= 0 ? info.index : '-'}}
          </td>
          <td>
            {{info.type.charAt(0).toUpperCase()}}
          </td>
          <td>
            <AddPackage  :action="'edit'" :packageKey="info.key" :response="packageInfo"/>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import AddPackage from '@/components/admin/AddPackage.vue';

export default {
  components: {
    AddPackage,
  },
  props: {
    packageInfo: Array,
    response: Object,
    title: {
      type: String,
      default: '',
    },
    total: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
.discount-info-table {
  width: 100%;
  &.item-report-summary {
    table {
      thead {
        th {
          border-bottom: size(1) solid var(--admin--text-level2);
          p {
            padding: 0;
            text-align: center;
            font-size: size(16);
            &.currency {
              padding: size(15) 0 0;
              text-align: center;
              font-size: size(12);
            }
          }
        }
      }
      tbody {
        tr {
          td {
            border-bottom: size(1) solid var(--admin--text-level2);
            &:nth-child(2), &:nth-child(3) {
              word-break: break-all;
              line-height: size(20);
            }
            span {
              vertical-align: top;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div
    class="admin-confirm-view package"
    :class="[`rootstate-${confirmState.value}`]">
    <div
      class="confirm-view-content"
    >
      <div
        class="state-loading"
        v-if="confirmState.isLoading">
        <rz-request-state :state="state" />
       <div class="overlay">
          <div class="wrapper">
            <div class="header">
              <h1 v-if="title" class="title">{{title}}</h1>
              <button
                aria-label="Close"
                class="btn btn-sm close"
                @click="doCancel"
              >X</button>
            </div>
            <div class="body">
              <rz-request-state :state="state" />
              <slot name="loading"></slot>
            </div>
          </div>
        </div>
      </div>
      <div
        class="state-loading"
        v-else-if="confirmState.isError">
        <div class="overlay">
          <div class="wrapper">
            <div class="header">
              <h1 v-if="title" class="title">{{title}}</h1>
              <button
                aria-label="Close"
                class="btn btn-sm close"
                @click="doCancel"
              >X</button>
            </div>
            <div class="body">
              <rz-request-state :state="state" />
              <slot name="error"></slot>
            </div>
          </div>
        </div>
      </div>
      <div
        class="state-confirm"
        v-else-if="confirmState.isAskConfirm"
      >
        <div class="overlay">
            <div class="wrapper">
              <div class="header">
                <h1 v-if="title" class="title">{{title}}</h1>
                <button
                  aria-label="Close"
                  class="btn btn-sm close"
                  @click="doCancel"
                >X</button>
              </div>
              <div class="body">
                <p v-if="message">{{message}}</p>
                <slot name="confirm"></slot>
                <p v-if="data.error" class="error">{{data.error}}</p>
                <div class="tools">
                  <button
                    class="btn btn-sm"
                    @click="doAction"
                  >Confirm</button>
                </div>
             </div>
            </div>
        </div>
      </div>
      <div
        class="state-completed"
        v-else-if="confirmState.isCompleted"
      >
        <slot name="completed">
          <span class="success">👍</span>
        </slot>
      </div>

      <div
        class="state-normal"
        v-if="showNormalState"
      >
        <svg class="edit-button" @click="doActionClick" v-if="actionButtonText === 'edit'">
          <use v-bind="{'xlink:href':'#edit-icon'}" />
        </svg>
        <button
          aria-label="Add Package"
          :class="[actionButtonClass]"
          v-if="actionButtonText === 'add'"
          @click="doActionClick"
        >Add Package</button>
        <slot name="normal"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ref,
  reactive,
  watch,
  computed,
} from 'vue';
import { RequestState, RequestStateStatus } from '@/api/core';
import { Enum } from '@/core/utils/enum';

const ConfirmState = new Enum({
  Normal: 'Normal',
  Loading: 'Loading',
  Error: 'Error',
  AskConfirm: 'AskConfirm',
  Completed: 'Completed',
});

export default {
  props: {
    action: Object,
    actionButtonText: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: 'Are you sure?',
    },
    actionButtonClass: {
      type: String,
      default: 'btn btn-sm',
    },
    message: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const state = ref(new RequestState());
    const confirmState = ref(ConfirmState.Normal);
    const showDone = ref(false);
    const data = reactive({
      error: '',
    });

    const inputFields = ['name', 'resource_key', 'type',
      'minAmountUsd', 'minAmountEur', 'minAmountInr', 'active', 'show_early_bird_pricing',
      'show_in_website', 'show_resource_page'];

    const validateInput = computed(() => {
      for (let i = 0; i < inputFields.length; i += 1) {
        if ((props.action[inputFields[i]] === '')
        || (props.action[inputFields[i]] === null)) {
          return false;
        }
      }
      return true;
    });

    const doAction = () => {
      if (validateInput.value) {
        emit('on-action-loading', props.action);
        state.value.status = RequestStateStatus.Loading;
        confirmState.value = ConfirmState.Loading;

        showDone.value = false;
        props.action.fn().then((response) => {
          if (response && response.code !== 40001) {
            state.value.status = RequestStateStatus.Loaded;
            confirmState.value = ConfirmState.Completed;
            props.action.cancelAsk();
            showDone.value = true;
            emit('on-action-done', props.action, response);
          } else if (response.code === 40001) {
            confirmState.value = ConfirmState.Error;
            state.value.ref = response;
            state.value.status = RequestStateStatus.Error;
            props.action.cancelAsk();
            emit('on-action-error', props.action, response.message);
          }
        }).catch((err) => {
          confirmState.value = ConfirmState.Error;
          state.value.ref = err;
          state.value.status = RequestStateStatus.Error;
          props.action.cancelAsk();
          emit('on-action-error', props.action, err);
        });
      } else {
        data.error = 'Please fill all the mandatory fields';
      }
    };

    const doInit = () => {
      state.value.status = RequestStateStatus.NotInit;
      confirmState.value = ConfirmState.Normal;
    };

    const doCancel = () => {
      doInit();
      props.action.cancel();
      emit('cancel', props.action);
    };

    const doActionClick = () => {
      doInit();
      props.action.askConfirm();
      console.log('C', props.action.ask);
      emit('on-action-click', props.action);
    };

    watch(() => props.action.ask, () => {
      console.log('Changed', props.action.ask, confirmState.value);
      if (props.action.ask && state.value.status.isLoaded) {
        doInit();
      } else if (props.action.ask && confirmState.value.isNormal) {
        confirmState.value = ConfirmState.AskConfirm;
      }
    });

    const showNormalState = computed(() => {
      if (confirmState.value.isLoading) {
        return false;
      }

      if (confirmState.value.isAskConfirm) {
        return false;
      }

      return true;
    });

    return {
      confirmState,
      doAction,
      doCancel,
      state,
      data,
      showDone,
      showNormalState,
      doActionClick,
      validateInput,
    };
  },
};
</script>
<style lang="scss" scoped>
.edit-button {
  width: size(20);
  height: size(15);
  fill: #fff;
  cursor: pointer;
}
.admin-confirm-view {
  &.package {
    width: auto;

    button {
      &.btn {
        margin-right: 0;
      }
    }
  }
  .state-confirm, .state-loading {
    input, textarea, select {
      width: size(250);
    }
    .overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      background: var(--admin--color-previewbg);
      height: 100vh;
      z-index: 100;
      .wrapper {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 100;
        width: size(530);
        overflow: hidden;
        z-index: 101;
        .header {
          background: var(--admin--color-level4);
          padding: size(5) 0 size(5) size(25);
          h1 {
            display: inline-block;
            width: 90%;
            text-transform: uppercase;
          }
          .close {
            float: right;
            background: none;
          }
        }
        .body {
          background: var(--admin--color-level3);
          overflow-y: auto;
          max-height: size(480);
          padding: size(25);
        }
      }
    }
  }
}
</style>

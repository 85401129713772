<template>
  <div class="screen-admin-page screen-admin-usaga-report discount-info">
    <div>
      <div class="toolbar">
        <div class="title">
          <h1>Packages</h1>
        </div>
        <AddPackage :action="'add'" />
      </div>
    </div>
    <div class="filterables">
      <div class="filter-container">
        <ul class="filter-items">
          <li
            v-for="item in filterables"
            :key="item.key"
            :class="{
              active: values.title === item,
              selectable: !item.isCheckbox,
              selected: item.selected,
              hr: item.key === '-',
            }"
            @click="onSelectedFilteritem(item)"
          >
            {{item}}
          </li>
        </ul>
      </div>
      <rz-request-state :state="state" />
      <div
        class="usage-report admin-screen-content"
        v-if="state.status.isLoaded"
      >
        <div class="table-block">
          <div class="item-list-row item-list">
            <PackageInfoTable
              :title="values.title"
              :packageInfo="packageInfo"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ref,
  watch,
  reactive,
  onMounted,
} from 'vue';
import { RequestState, RequestStateStatus } from '@/api/core';
import {
  ProjectList,
} from '@/store/modules/admin';
import PackageInfoTable from '@/components/admin/PackageInfoTable.vue';
import AddPackage from '@/components/admin/AddPackage.vue';

export default {
  components: {
    PackageInfoTable,
    AddPackage,
  },
  setup() {
    const state = ref(new RequestState());
    let filterables = ref([]);
    const packageInfo = ref({});
    const title = ref('Package Info');

    const values = reactive({
      show_active: true,
      title: 'Active Packages',
    });

    filterables = [
      'Active Packages',
      'Inactive Packages',
    ];

    const onSelectedFilteritem = (item) => {
      if (item === 'Active Packages') {
        values.show_active = true;
        values.title = 'Active Packages';
      } else {
        values.show_active = false;
        values.title = 'Inactive Packages';
      }
    };

    const fetchData = () => {
      state.value.status = RequestStateStatus.Loading;
      const params = {
        active: values.show_active,
      };
      return ProjectList.fetchPackageInfo(params).then((resp) => {
        packageInfo.value = resp.response;
        state.value.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      });
    };
    onMounted(() => {
      fetchData();
    });

    watch(() => values.show_active, () => {
      fetchData();
    });

    return {
      packageInfo,
      state,
      title,
      values,
      filterables,
      onSelectedFilteritem,
    };
  },
};
</script>
<style lang="scss">
.discount-info {
  .filterables {
    margin: 0 size(30);
    .filter-container {
      flex: 0 0 size(250);
    }
  }
  .table-block {
    display: inline-block;
    vertical-align: top;
    min-width: size(800);
    .item-list {
      padding-top: 0;
      table {
        tr {
          &:hover {
            background: transparent;
          }
          td {
            &:first-child{
              cursor: default;
              button {
                cursor: default;
              }
            }
          }
        }
      }
    }
  }
}
</style>
